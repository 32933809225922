import { useDispatch } from "react-redux";
import detailsActions from "@components/Redux/Checkout/Details/actions";
// import userActions from "@components/Redux/User/actions";
// import modalActions from "@components/Redux/Modals/actions";

const useCheckoutDetailsDispatch = () => {
    const dispatch = useDispatch();

    const SET_DETAILS = (details) => {
        dispatch({type: detailsActions.CHECKOUT_DETAILS_SET_DETAILS, payload: details})
    }

    const SET_DETAILS_KEY = (key, value) => {
        dispatch({type: detailsActions.CHECKOUT_DETAILS_SET_KEY, payload: {key: key, value: value}})
    }

    const SET_DETAILS_KEY_V2 = (value) => {
        dispatch({type: detailsActions.SET_DETAILS_KEY, payload: value})
    }

    const RESET_DETAILS = () => {
        dispatch({type: detailsActions.CHECKOUT_DETAILS_RESET})
    }

    return {
        SET_DETAILS,
        SET_DETAILS_KEY,
        SET_DETAILS_KEY_V2,
        RESET_DETAILS
    }
}

export default useCheckoutDetailsDispatch;